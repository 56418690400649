import { Backdrop, CircularProgress } from '@material-ui/core';
import PropTypes from "prop-types";
import React from 'react';
import styled from 'styled-components';

const LoadingBackdrop = styled(Backdrop)`
  color: #fff;
  z-index: 10000;
`;

function FullscreenLoading(props) {
  const {
    open
  } = props;
  return (
    <LoadingBackdrop open={open}>
      <CircularProgress color="inherit" />
    </LoadingBackdrop>
  );
}

FullscreenLoading.defaultProps = {
  open: false
}
FullscreenLoading.propTypes = {
  open: PropTypes.bool
}

export default FullscreenLoading;
