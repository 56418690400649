import * as yup from "yup";

import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Container from "@material-ui/core/Container";
import Copyright from "./Copyright"
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React from 'react';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { useForm } from "react-hook-form";

const SubmitButton = styled(Button)`
  ${({ theme }) => `
    margin: ${theme.spacing(3, 0, 2)}
  `}
`;

const Form = styled.form`
  ${({ theme }) => `
    width: 100%;
    margin-top: ${theme.spacing(1)}px;
  `}
`;

const FormPaper = styled(Paper)`
  ${({ theme }) => `
    align-items: center
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacing(8)}px;
    padding: ${theme.spacing(4)}px;
  `}
`

const formValidationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required()
})

export default function SignInForm(props) {
  const {
    formErrorMessage,
    loading,
    onSubmit
  } = props;

  const {register, handleSubmit, errors} = useForm({validationSchema: formValidationSchema});

  return (
    <Container component="main" maxWidth="xs">
      <FormPaper elevation={0}>
        <Typography component="h1" variant="h5">
          Sign In & Welcome!
        </Typography>
        <Collapse in={formErrorMessage ? true : false} style={{ width: '100%'}}>
          <Alert severity="error">{formErrorMessage}</Alert>
        </Collapse>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            defaultValue=""
            disabled={loading}
            autoComplete="email"
            autoFocus
            error={errors['email'] ? true : false}
            fullWidth
            helperText={errors['email'] ? errors['email'].message : null}
            id="email"
            inputRef={register}
            label="Email address"
            margin="normal"
            name="email"
            variant="outlined"
          />
          <TextField
            defaultValue=""
            disabled={loading}
            autoComplete="current-password"
            error={errors['password'] ? true : false}
            fullWidth
            helperText={errors['password'] ? errors['password'].message : null}
            label="Password"
            id="password"
            inputRef={register}
            margin="normal"
            name="password"
            type="password"
            variant="outlined"
          />
          <SubmitButton
            color="primary"
            disabled={loading}
            fullWidth
            type="submit"
            variant="contained"
          >
            Sign In
            {loading && <CircularProgress size={24} />}
          </SubmitButton>
        </Form>
      </FormPaper>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

SignInForm.propTypes = {
  formErrorMessage: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}