import { gql } from 'apollo-boost';

export const SIGNIN_USER = gql`
  mutation SignInUser(
    $email: String!
    $password: String!
  ){
    tokenAuth(input: {
      email: $email,
      password: $password
    }) {
      token
      refreshToken
      errors
      success
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation LogoutUser($refreshToken: String!) {
    revokeToken(input: {
      refreshToken: $refreshToken
    }) {
      success
      revoked
      errors
    }
  }
`;

export const LOCAL_INFO = gql`
  {
    userMerchantList @client {
      id
      name
    }
    userSaleFormList @client {
      id
      name
      merchant {
        id
      }
    }
  }
`;

export const USER_INFO = gql`
  query UserInfo {
    me {
      email
      firstName
      lastName
      merchants {
        edges {
          node {
            id
            name
            saleForms {
              edges {
                node {
                  id
                  name
                  description
                  merchant {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const VERIFY_TOKEN_MUTATION = gql`
  mutation VerifyTokenMutation($token: String!) {
    verifyToken(input: {
      token: $token
    }) {
      success
      errors
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshTokenMutation($refreshToken: String!) {
    refreshToken(input: {
      refreshToken: $refreshToken
    }) {
      token
      refreshToken
      success
      errors
      payload
    }
  }
`;

export const CREATE_TRANSACTION_REPORT = gql`
  mutation CreateTransactionReport(
  $merchantIds:[ID!], 
  $saleFormIds: [ID!]!,
  $status: [String!]!,
  $startDatetime: DateTime!,
  $endDatetime: DateTime!,
) {
  createTransactionReport(input:{
    merchantIds: $merchantIds,
    saleFormIds: $saleFormIds,
    status: $status,
    startDatetime: $startDatetime,
    endDatetime: $endDatetime
  }) {
    task {
      id
      type
      status
      params
      result
    }
  }
}
`;

export const TASK = gql`
  query Task($id: ID!) {
  task(id: $id) {
    id
    status
    type
    result
    params
  }
}
`;

// export const SIGNIN_USER = gql`
//   query MerchantSaleForm($domainName: String!) {
//     merchantFormDomainName(domainName: $domainName) {
//       merchantSaleForm {
//         allowPrefill
//         allowPrefillRedirect
//         description
//         name
//         primaryColor
//         secondaryColor
//         logo
//         formFields {
//           edges {
//             node {
//               askConfirmation
//               id
//               internalName
//               length
//               name
//               order
//               required
//               type
//               options {
//                 edges {
//                   node {
//                     name
//                     value
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

// export const CREATE_ORDER = gql`
//   mutation createOrder(
//     $formDomain: String!, 
//     $price: Float!,
//     $extraData: JSONString
//   ){
//     createOrder(
//       formDomain: $formDomain
//       price: $price
//       extraData: $extraData
//     ) {
//       order {
//         status
//         redirectUrl
//       }
//     }
//   }
// `;