import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';

import AccountMenu from './AccountMenu';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Copyright from './Copyright';
import DownloadCard from './DownloadCard';
import { LOCAL_INFO } from '../data/queries';
import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';

// const DrawerPaper = styled
const AppBarSpacer = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.spacing(10)}px
  `}
`;

const Content = styled.main`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
`;

const DashboardContainer = styled(Container)`
  ${({ theme }) => `
    padding-top: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(4)};
  `}
`;

// const MenuButton = styled(IconButton)`
//   margin-right: 36px;
// `;

const Root = styled.div`
  display: flex;
`;

const DashboardToolbar = styled(Toolbar)`
  padding-right: 24;
`;

const ToolbarIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
`;

const ToolbarTitle = styled(Typography)`
  flex-grow: 1;
`;


export default function Dashboard(props) {
  const [open, setOpen] = React.useState(false);
  
  const handleDrawerToggle = () => {
    setOpen(!open);
  }
  const {data: {userMerchantList, userSaleFormList}}= useQuery(LOCAL_INFO);

  return (
    <Root>
      <AppBar position="absolute">
        <DashboardToolbar variant='dense'>
          {/* <MenuButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </MenuButton> */}
          <ToolbarTitle component="h1" variant="h6" color="inherit" noWrap >
            Dashboard
          </ToolbarTitle>
          <AccountMenu />
        </DashboardToolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        open={open}
      >
        <ToolbarIcon>
          <IconButton onClick={(handleDrawerToggle)}>
            <ChevronLeftIcon />
          </IconButton>
        </ToolbarIcon>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <AssessmentOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Transaction</ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          {/* Secondary list should be here */}
        </List>
      </Drawer>
      <Content>
        <AppBarSpacer />
        <DashboardContainer maxWidth="lg">
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={4}>
              <Paper>
                <DownloadCard
                  merchants={userMerchantList}
                  saleForms={userSaleFormList}
                />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper>
                {/* <Deposits /> */}
              </Paper>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper>
                {/* <Orders /> */}
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </DashboardContainer>
      </Content>
    </Root>
  )
}