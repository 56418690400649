import { AUTH_TOKEN, REFRESH_TOKEN } from "../constants";
import React, { useState } from 'react';

import { Mutation } from "@apollo/react-components";
import { SIGNIN_USER } from '../data/queries';
import SignInForm from "./SignInForm";
import { useHistory } from "react-router-dom";

export default function SignIn(props) {

  let history = useHistory()
  let [errorMessage, setErrorMessage] = useState();
  // let [loading, setLoading] = useState();

  const signInResponse = (data) => {
    setErrorMessage(null);
    
    if (data.tokenAuth && data.tokenAuth.success && data.tokenAuth.token) {
      localStorage.setItem(AUTH_TOKEN, data.tokenAuth.token);
      localStorage.setItem(REFRESH_TOKEN, data.tokenAuth.refreshToken);
      history.push('/');
    } else if (data.tokenAuth && data.tokenAuth.errors) {
      for (let key in data.tokenAuth.errors) {
        data.tokenAuth.errors[key].forEach(({message}) => { setErrorMessage(message) });
      }
    } else {
      setErrorMessage('Error. Please try again later');
    }
  }

  return (
    <Mutation
      mutation={SIGNIN_USER}
      onCompleted={signInResponse}
      onError={signInResponse}
    >
      {(mutate, {loading}) => (
        <SignInForm 
          formErrorMessage={errorMessage}
          onSubmit={formData => mutate({variables: formData})}
          loading={loading}
        />
      )}
    </Mutation>
  )
}