export const AUTH_TOKEN = "auth-token";
export const DEFAULT_CONSTANTINE_THEME = {
  palette: {
    primary: {  
      main: '#23a455'
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
  },
};
export const REFRESH_TOKEN = "refresh-token";
export const REFRESH_TOKEN_INTERVAL = 240;
