import 'typeface-open-sans';

import {
  AUTH_TOKEN,
  DEFAULT_CONSTANTINE_THEME
} from './constants';
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  createMuiTheme,
} from '@material-ui/core';
import {
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";

import ApolloClient from 'apollo-boost'
import { ApolloProvider } from "@apollo/react-hooks";
import AuthRequired from "./components/AuthRequired";
import Dashboard from "./components/Dashboard";
import { InMemoryCache } from 'apollo-cache-inmemory';
import React from 'react';
import SignIn from './components/SignIn';
import { ThemeProvider } from 'styled-components';

const cache = new InMemoryCache();

const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_URL,
  cache: cache,
  request: (operation) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    operation.setContext({
      headers: {
        authorization: token ? `JWT ${token}` : ''
      }
    })
  }
});

cache.writeData({
  data: {
    userMerchantList: [],
    userSaleFormList: []
  }
})

// Default constantine theme according to const
const defaultConstantineTheme = createMuiTheme(DEFAULT_CONSTANTINE_THEME);

function App() {
  const [constantineFormsTheme,] = React.useState(defaultConstantineTheme);
  
  return (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={constantineFormsTheme}>
      <ThemeProvider theme={constantineFormsTheme}>
        <CssBaseline />
        <ApolloProvider client={client}>
          <Router>
            <Switch>
              <Route path="/login">
                <SignIn />
              </Route>
              <AuthRequired>
                <Route path="/">
                  <Dashboard />
                </Route>
              </AuthRequired>
            </Switch>
          </Router>
        </ApolloProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
  );
}

export default App;
