import { AUTH_TOKEN, REFRESH_TOKEN } from '../constants';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  IconButton
} from '@material-ui/core';
import { LOGOUT_USER } from '../data/queries';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Mutation } from '@apollo/react-components';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const StyledMenu = styled(Menu)`
  & paper: {
    border: 1px solid #d3d4d5;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  ${({ theme }) => `
    &:focus: {
      background-color: ${theme.palette.primary.main};
      & .MuiListItemIcon-root, & .MuiListItemText-primary: {
        color: ${theme.palette.common.white}
      }
    }
  `}
`;

const ReferenceMutation = React.forwardRef((props, ref) => {
  return <Mutation {...props} innerRef={ref} />
});

export default function AccountMenu() {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = () => {
    // @todo: expire token in backend and delete cache of apolo
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    history.push('/login');
  }

  return (
    <div>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="inherit"
        onClick={handleClick}
      >
        {/* <Badge badgeContent={4} color="secondary">
          <AccountCircleIcon />
        </Badge> */}
        <AccountCircleIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ReferenceMutation
          mutation={LOGOUT_USER}
          variables={{refreshToken: localStorage.getItem(REFRESH_TOKEN)}}
          onCompleted={data => logoutUser(data)}
          onError={logoutUser}
        >
          {mutate => (
            <StyledMenuItem onClick={() => mutate()}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </StyledMenuItem>
          )} 
        </ReferenceMutation>
      </StyledMenu>
    </div>
  );
}