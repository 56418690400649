import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  REFRESH_TOKEN_INTERVAL
} from "../constants";
import {
  REFRESH_TOKEN_MUTATION,
  USER_INFO,
  VERIFY_TOKEN_MUTATION
} from '../data/queries';
import {
  useMutation,
  useQuery
} from '@apollo/react-hooks';

import FullscreenLoading from "./FullscreenLoading";
import React from 'react';
import moment from 'moment';
import {
  useHistory
} from "react-router-dom";

export default function SignIn(props) {

  let history = useHistory()

  const [token, setToken] = React.useState(localStorage.getItem(AUTH_TOKEN));
  const [refreshToken, setRefreshToken] = React.useState(localStorage.getItem(REFRESH_TOKEN));
  const [tokenValidUntil, setTokenValidUntil] = React.useState(null);
  
  const handleRefreshTokenComplete = (data) => {
    if (data.refreshToken && data.refreshToken.success && data.refreshToken.token) {
      localStorage.setItem(AUTH_TOKEN, data.refreshToken.token);
      localStorage.setItem(REFRESH_TOKEN, data.refreshToken.refreshToken);
      setToken(data.refreshToken.token);
      setRefreshToken(data.refreshToken.refreshToken);
    } else {
      logout();
    }
  }

  const [callRefreshToken,] = useMutation(REFRESH_TOKEN_MUTATION, {
    variables: {refreshToken: refreshToken},
    onCompleted: handleRefreshTokenComplete,
    onError: (error) => {
      console.error(error);
      logout();
    }
  });
  

  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    setRefreshToken(null);
    setToken(null);
    history.push('/')
  }

  const [verifyToken,] = useMutation(VERIFY_TOKEN_MUTATION, {
    variables: {token: token},
    onCompleted: (data) => {
      if (data.verifyToken.success) {
        setTokenValidUntil(moment().add(REFRESH_TOKEN_INTERVAL, 'seconds'));
        setTimeout(() => {
          verifyToken();
        }, REFRESH_TOKEN_INTERVAL * 1000)
      } else {
        callRefreshToken();
      }
    },
    onError: (error) => {
      console.error(error);
      logout();
    }
  });
  
  React.useEffect(() => {
    // Token not empty
    if (token !== null && token !== "") {
      // Date of refreshing 
      if (tokenValidUntil == null || (tokenValidUntil && tokenValidUntil.isSameOrBefore(moment()))) {
        if (tokenValidUntil) {
          console.log(tokenValidUntil.isSameOrAfter(moment()))
          console.log(tokenValidUntil)
        }
        verifyToken();
      }
    }
  }, [verifyToken, token, tokenValidUntil])


  // Get user info as personal info, related data, among others.
  // @todo: manage error
  const {data, loading, client, refetch} = useQuery(USER_INFO, {
    onError: (error) => {
      console.log(error);
    }
  });
  React.useEffect(() => {
    if (data && data.me) { 
      let merchants = data.me.merchants.edges.map((merchant) => { return merchant.node });
      let saleForms = merchants.map((merchant) => {
        return merchant.saleForms.edges.map((saleForm) => { return saleForm.node });
      }).flat();
      client.writeData({data: {userMerchantList: merchants}});
      client.writeData({data: {userSaleFormList: saleForms}});
    }
  }, [data, client])
  // Refetch USER_INFO in case token is updated
  React.useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);
  
  if (loading) return <FullscreenLoading />;
  
  if (!token) {
    return (
      <React.Fragment>
        {history.push('/login')}
      </React.Fragment>
    )
  }
  
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}