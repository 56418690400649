import 'moment/locale/es';

import {
  CREATE_TRANSACTION_REPORT,
  TASK
} from '../data/queries';
import {
  Chip,
  Input,
  LinearProgress
} from '@material-ui/core';
import {
  DateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '@material-ui/core/Select';
// moment
import moment from 'moment';

moment.locale('es');

const TRANSACTION_STATUS = [
  { name: 'Successful', value: 'successful' },
  { name: 'Cancelled', value: 'cancelled' },
  { name: 'Expired', value: 'expired' },
  { name: 'Awaiting Assignment', value: 'awaiting_assignment' },
]

export default function DownloadDialog(props) {

  const {
    merchants,
    onClose,
    open,
    saleForms
  } = props;

  const [startDate, setStartDate] = React.useState(moment().startOf('day'));
  const [endDate, setEndDate] = React.useState(moment().endOf('day'));
  const [statusFilter, setStatusFilter] = React.useState([TRANSACTION_STATUS[0]]);
  const [merchantsFilterValue, setMerchantsFilterValue] = React.useState([]);
  const [saleFormsFilterValue, setSaleFormsFilterValue] = React.useState([]);
  const [filteredSaleForms, setFilteredSaleForms] = React.useState(saleForms);

  const [reportUrl, setReportUrl] = React.useState(null);
  const [creatingReport, setCreatingReport] = React.useState(false);
  const [pendingTask,setPendingTask] = React.useState(null);

  const [createTransactionReport,] = useMutation(CREATE_TRANSACTION_REPORT, {
    variables: {
      merchantIds: merchantsFilterValue.map((m) => { return m.id}),
      saleFormIds: saleFormsFilterValue.map((f) => { return f.id}),
      startDatetime: startDate,
      endDatetime: endDate,
      status: statusFilter.map((s) => { return s.value})
    },
    onCompleted: (data) => {
      setPendingTask(data.createTransactionReport.task)
    },
    onError: (error) => {
      console.error(error);
    }
  });

  
  const [getTask, {called, data, stopPolling}] = useLazyQuery(TASK, {
    pollInterval: 2000,
  });

  // set default merchant to first one
  React.useEffect(() => {
    if (merchants && merchants.length > 0) {
      setMerchantsFilterValue([merchants[0]]);
    }
  }, [merchants])

  React.useEffect(() => {
    if (data && data.task) {
      setPendingTask(data.task)
    }
  }, [data])
  
  React.useEffect(() => {
    if (pendingTask && pendingTask.status === 'SUCCESSFUL') {
      if (stopPolling) { stopPolling(); }
      setReportUrl(JSON.parse(pendingTask.result));
      setCreatingReport(false);
    } else if (pendingTask && pendingTask.status === 'FAILED') {
      if (stopPolling) { stopPolling(); }
      setCreatingReport(false);
      console.error(pendingTask)
    } else if (pendingTask && pendingTask.id) {
      getTask({variables: {id: pendingTask.id}})
    }
  }, [pendingTask, called, getTask, stopPolling])


  const handleCreateReportButton = () => {
    setCreatingReport(true);
    createTransactionReport();
  }

  const hanldeDownloadReport = () => {
    window.open(reportUrl, '_blank');
    setReportUrl(null);
    setPendingTask(null);
    onClose();
  }


  const handleStartDateChange = (startDate) => {
    setStartDate(startDate);
  }
  const handleEndDateChange = (endDate) => {
    setEndDate(endDate);
  }

  const handleStatusSelectChange = (event) => {
    setStatusFilter(event.target.value);
  }

  const handleMerchantsSelectChange = (event) => {
    setMerchantsFilterValue(event.target.value);
    let availableSaleForms = saleForms.filter((form) => {
      let findResult = event.target.value.findIndex((m) => {
        return m.id && m.id === form.merchant.id
      });
      return findResult > -1
    });
    setFilteredSaleForms(availableSaleForms);
    if (availableSaleForms.length > 0) {
      setSaleFormsFilterValue([availableSaleForms[0]]) 
    } else {
      setSaleFormsFilterValue([]);
    }
  }
  
  const handleSaleFormsSelectChange = (event) => {
    setSaleFormsFilterValue(event.target.value);
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Dialog
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={onClose}
      >
        <DialogTitle id="form-dialog-title">Download Transaction Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {creatingReport ?
              <React.Fragment>
                Please wait while the report is being created...
                <LinearProgress />
              </React.Fragment>
              : (
                !reportUrl ?
                  <React.Fragment>
                    Set Merchant, Form, Status and Date range filters then press the Create Report button. After a while the report will be ready to download.
                  </React.Fragment>
                :
                  <React.Fragment>
                    Press the button bellow to start downloading the report file
                  </React.Fragment>
              )
            }
          </DialogContentText>
          {reportUrl ?
            <Button 
              color='primary'
              onClick={hanldeDownloadReport} 
              startIcon={<CloudDownloadIcon>Download</CloudDownloadIcon>}
            >Download File</Button>
          : null}
          {!reportUrl && !creatingReport ?
            <form>
              <Grid container spacing={4} direction='row' justify='flex-start' alignItems='center'>
                <Grid item xs={4}>
                  <DateTimePicker
                    autoOk
                    disableFuture
                    format='YYYY-MM-DD HH:mm'
                    showTodayButton
                    hideTabs
                    label="Start"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateTimePicker
                    autoOk
                    disableFuture
                    format='YYYY-MM-DD HH:mm'
                    showTodayButton
                    hideTabs
                    label="End"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel htmlFor="order-status">Status</InputLabel>
                  <Select
                    fullWidth
                    inputProps={{ name: 'order-status', id: 'order-status' }}
                    input={<Input id='select-multiple-chip' />}
                    multiple
                    onChange={handleStatusSelectChange}
                    renderValue={(selected) => (
                      <div>
                        {
                          selected.map((value) => (
                            <Chip key={value.value} label={value.name} />
                          ))
                        }
                      </div>
                    )}
                    value={statusFilter}
                  >
                    {TRANSACTION_STATUS.map((status) => (
                      <MenuItem key={status.value} value={status}>{status.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={4}>
                  <InputLabel htmlFor="order-status">Merchants</InputLabel>
                  <Select
                    fullWidth
                    inputProps={{ name: 'order-status', id: 'order-status' }}
                    input={<Input id='select-multiple-chip' />}
                    multiple
                    onChange={handleMerchantsSelectChange}
                    renderValue={(selected) => (
                      <div>
                        {selected.map((merchant) => (
                          <Chip key={merchant.id} label={merchant.name} />
                        ))}
                      </div>
                    )}
                    value={merchantsFilterValue}
                  >
                    {merchants.map((merchant) => (
                      <MenuItem key={merchant.id} value={merchant}>{merchant.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={4}>
                  <InputLabel htmlFor="order-sale-forms">Sale Form</InputLabel>
                  <Select
                    fullWidth
                    inputProps={{ name: 'order-sale-forms', id: 'order-sale-forms' }}
                    input={<Input id='select-multiple-chip' />}
                    multiple
                    onChange={handleSaleFormsSelectChange}
                    renderValue={(selected) => (
                      <div>
                        {selected.map((saleForm) => (
                          <Chip key={saleForm.id} label={saleForm.name} />
                        ))}
                      </div>
                    )}
                    value={saleFormsFilterValue}
                  >
                    {filteredSaleForms.map((saleForm) => (
                      <MenuItem key={saleForm.id} value={saleForm}>{saleForm.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </form>
            : null
          }
        </DialogContent>
        <DialogActions>
          <Box mt={2} mb={2}>
            {!reportUrl && !creatingReport ?
              <React.Fragment>
                <Button onClick={onClose} disabled={creatingReport}>Cancel</Button>
                <Button
                  color="primary"
                  onClick={handleCreateReportButton}
                  endIcon={<FileCopyOutlinedIcon />}
                >
                  Create Report
                </Button>
              </React.Fragment>
              : null
            }
          </Box>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  )
}


DownloadDialog.propTypes = {
  merchants: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  saleForms: PropTypes.array.isRequired,
}