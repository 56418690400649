import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import DownloadDialog from './DownloadDialog';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const SecondaryText = styled(Typography)`
  font-size: 14;
`;

export default function DownloadCard(props) {
  const {
    merchants,
    saleForms
  } = props;

  const [openDialog, setOpenDialog] = React.useState(false);
  

  return (
    <React.Fragment>
      <DownloadDialog
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        merchants={merchants}
        saleForms={saleForms}
      />
      <Card>
        <CardContent>
          <SecondaryText color='textSecondary' gutterBottom>
            Actions
          </SecondaryText>
          <Typography variant='h5' component='h2'>
            Download Transaction Report
          </Typography>
          <Typography variant="body2" component='p'>
            You can download a .csv file with filtered transactions of a period of time. Press Download Report button to continue.
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => setOpenDialog(true)} size='small' color='primary' endIcon={<CloudDownloadIcon>Download</CloudDownloadIcon>}>
            Download Report
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  )
}

DownloadCard.propTypes = {
  merchants: PropTypes.array.isRequired,
  onButtonClick: PropTypes.func,
  saleForms: PropTypes.array.isRequired,
}